import Vue from 'vue'
import moment from 'moment'
import { upperFirst } from 'lodash'

Vue.filter('limit', (string, limit = 50) => {
  if (string.length > limit) {
    return string.substring(0, (limit - 3)) + '...'
  }
  return string
})

Vue.filter('money', cents => {
  let dollars = cents / 100;
  return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
})

Vue.filter('dateFormat', (date, format = 'MM/DD/YY HH:mm') => {
  const _date = moment(date)
  if (_date.isValid()) {
    return _date.format(format)
  }
  return null
})

Vue.filter('dateFormatUTC', (date, format = 'MM/DD/YY HH:mm') => {
  const _date = moment.utc(date)
  if (_date.isValid()) {
    return _date.format(format)
  }
  return null
})

Vue.filter('ucfirst', string => {
  return upperFirst(string)
})

Vue.filter('shortAgo', string => {
  let seconds = Math.floor((new Date() - moment.utc(string)) / 1000)
  let intervalType

  let interval = Math.floor(seconds / 31536000)
  if (interval >= 1) {
    intervalType = 'y'
  } else {
    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
      intervalType = 'mo'
    } else {
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        intervalType = 'd'
      } else {
        interval = Math.floor(seconds / 3600)
        if (interval >= 1) {
          intervalType = 'h'
        } else {
          interval = Math.floor(seconds / 60)
          if (interval >= 1) {
            intervalType = 'm'
          } else {
            return '< than 1 minute'
          }
        }
      }
    }
  }

  return interval + intervalType
})

Vue.filter('ago', string => {
  return moment.utc(string).fromNow()
})

Vue.filter('truncate', (value, len = 20) => {
  return value.length > len ? `${value.substring(0, len)}...` : value
})

Vue.filter('customerRole', (value) => {
  switch (value) {
    case 'marketing':
      return 'Marketing'
    case 'revenue':
      return 'Revenue / Reservations'
    case 'gm':
      return 'General / Hotel manager'
    case 'catering':
      return 'Restaurant Catering'
    case 'sales':
      return 'Sales'
    case 'accounting':
      return 'Accounting / Finance'
    case 'hr':
      return 'Human resources'
    case 'operations':
      return 'Front office / Director of rooms / Housekeeping'
  }
})

Vue.filter('shortName', someone => {
  if (someone.first_name && someone.last_name) {
    return `${someone.first_name} ${someone.last_name[0]}.`
  } else {
    return someone.full_name || someone.email
  }
})

Vue.filter('nth', d => {
  if (d > 3 && d < 21) return d + 'th'
  switch (d % 10) {
    case 1:
      return d + 'st'
    case 2:
      return d + 'nd'
    case 3:
      return d + 'rd'
    default:
      return d + 'th'
  }
})

Vue.filter('humanizeFileSize', bytes => {
  const si = 1024
  const thresh = 1024
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return bytes.toFixed(1) + ' ' + units[u]
})

Vue.filter('tagHumanReadable', str => upperFirst(
  str.replace(/_/gi, ' ').replace(/gold|silver/gi, word => '(' + word.toUpperCase() + ')')
))
